import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VFileInput,{attrs:{"name":"file","label":_vm.title,"disabled":_vm.isDisabled,"accept":_vm.acceptType,"rules":_vm.isRequired ? [_vm.fileInputModel.length ? true : '这是必选项'] : [!_vm.file ? true : '已选择文件，请先上传或清空文件'],"dense":"","outlined":"","multiple":"","show-size":"","hide-details":"auto","clearable":"","value":_vm.fileInputModel},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),(!_vm.isDisabled)?_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex justify-space-around"},[(!_vm.isShowProgressBar)?_c(VBtn,{staticClass:"me-2 mt-1",attrs:{"color":"success","small":""},on:{"click":_vm.uploadFiles}},[_vm._v(" 上传 ")]):_vm._e(),(_vm.isShowProgressBar && !_vm.isPaused)?_c(VBtn,{staticClass:"me-2 mt-1",attrs:{"outlined":"","small":""},on:{"click":_vm.uploadPause}},[_vm._v(" 暂停 ")]):_vm._e(),(_vm.isShowProgressBar && _vm.isPaused)?_c(VBtn,{staticClass:"me-2 mt-1",attrs:{"outlined":"","small":""},on:{"click":_vm.uploadResume}},[_vm._v(" 继续 ")]):_vm._e(),(_vm.isShowProgressBar)?_c(VBtn,{staticClass:"me-4 mt-1",attrs:{"small":"","color":"error"},on:{"click":_vm.uploadCancel}},[_vm._v(" 取消 ")]):_vm._e()],1)]):_vm._e()],1),(_vm.isShowProgressBar)?_c(VProgressLinear,{staticClass:"mt-2",attrs:{"value":_vm.progress.value,"height":"20","striped":""}},[_vm._v(" "+_vm._s(Math.ceil(_vm.progress.value))+"% ")]):_vm._e(),(_vm.filesUploaded.length !== 0)?_c(VBtn,{staticClass:"mt-2 mx-2",attrs:{"color":"error","small":""},on:{"click":_vm.uploadFilesDelete}},[_vm._v(" 删除所有上传文件 ")]):_vm._e(),_vm._l((_vm.filesUploaded),function(fileItem,index){return _c(VTooltip,{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mt-2 mx-2",attrs:{"text-color":"primary","color":"primary"},on:{"click":function($event){return _vm.uploadFileDelete(index)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(fileItem.name || fileItem.key.split('/').pop())+_vm._s(fileItem.duration ? (" | " + (Math.floor(fileItem.duration / 60)) + "分" + (Math.floor(fileItem.duration % 60)) + "秒") : '')+" ")])]}}],null,true)},[_c('span',[_vm._v("点击删除该上传文件")])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }